import React, { useEffect, useState } from "react";
import { filterByCategory, searchCards, sortCards } from "../util/helpers";
import { StacksTemplate } from '../templates/stacks';
import { graphql } from "gatsby";
import lunr from "lunr";

const Trainings = ({ data }) => {

  const allCards = [
    ...("allWpTraining" in data ? data.allWpTraining.edges || [] : []),
  ]

  const stackPages = {
    "destinations": data.wp.tenjinSettings.acf.destinationsPage,
    "sources": data.wp.tenjinSettings.acf.sourcesPage,
    "analyses": data.wp.tenjinSettings.acf.analysesPage,
    "training": data.wp.tenjinSettings.acf.trainingPage
  }

  const stackMenuLabels = {
    "stacks": data.wp.tenjinSettings.acf.stacksMenuLabel,
    "destinations": data.wp.tenjinSettings.acf.destinationsMenuLabel,
    "sources": data.wp.tenjinSettings.acf.sourcesMenuLabel,
    "analyses": data.wp.tenjinSettings.acf.analysesMenuLabel,
    "training": data.wp.tenjinSettings.acf.trainingMenuLabel
  }

  const [filter, setFilter] = useState(null)

  const [categoryFilter, setCategoryFilter] = useState(null)

  const [searchQuery, setSearchQuery] = useState("")

  const [searchIndex, setSearchIndex] = useState(null)
  let cardsToDisplay = [];
  let searchResultCount = null;

  (() => {
    if (searchIndex && searchQuery) {
      cardsToDisplay = searchCards(searchIndex, searchQuery, allCards)
    } else {
      cardsToDisplay = allCards
    }

    cardsToDisplay = filterByCategory(cardsToDisplay, categoryFilter)

    if (filter) sortCards(cardsToDisplay, filter)

    searchResultCount = cardsToDisplay.length
  })()

  useEffect(() => {
    setSearchIndex(buildSearchIndex())
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const buildSearchIndex = () => {
    return lunr(function () {
      this.ref("id")
      this.field("title")

      this.pipeline.remove(lunr.stemmer)
      this.searchPipeline.remove(lunr.stemmer)

      allCards.map(card => {
        return this.add({
          id: card.node.id,
          title: card.node.title,
        })
      })
    })
  }

  const settings = {
    speed: 500,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1.3,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.3,
          arrow: false,
        },
      },
      {
        breakpoint: 1024,
        slidesToShow: 1.3,
        arrow: false,
      },
      {
        breakpoint: 99999999,
        settings: "unslick",
      },
    ],
  }
  return (
    <StacksTemplate
      seoTitle={`${stackPages?.training?.title ?? 'Training'} | Growth FullStack`}
      seoPost={data.wp.tenjinSettings.acf.trainingPage}
      stackPages={stackPages}
      stackMenuLabels={stackMenuLabels}
      currentPage='training'
      categories={data.allWpCategory.edges}
      {...{
        data,
        setSearchQuery,
        filter,
        setFilter,
        searchQuery,
        searchResultCount,
        cardsToDisplay,
        settings,
        setCategoryFilter,
        categoryFilter
      }}
    />
  )
}

export const pageQuery = graphql`
  query {
    allWpTraining(sort: { fields: [menuOrder], order: ASC }) {
      edges {
        node {
          id
          acf {
            tenjinArticleNumber
            tenjinHighlightCard
            tenjinHeadline
            tenjinRelatedContent {
              ... on WpTraining {
                databaseId
                nodeType
                title
                slug
                uri
                acf {
                  tenjinCardExcerpt
                  tenjinArticleNumber
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                  tenjinPaidContent
                  tenjinRelatedContent {
                    ... on WpTraining {
                      databaseId
                      nodeType
                      title
                      slug
                      uri
                      acf {
                        tenjinCardExcerpt
                        tenjinArticleNumber
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                        tenjinPaidContent
                      }
                    }
                    ... on WpAnalyse {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconAlt
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                    ... on WpDestination {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconAlt
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                    ... on WpSource {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconAlt
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                  }
                }
              }
              ... on WpAnalyse {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconAlt
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
              ... on WpDestination {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconAlt
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
              ... on WpSource {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconAlt
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
            }
            tenjinTrainingSteps {
              tenjinStepTitle
              tenjinTrainingStepContent
            }
            tenjinCardExcerpt
            tenjinUpcomingContent
            tenjinNoContent
            tenjinNewContent
            tenjinPopularity
            tenjinPaidContent
          }
          title
          nodeType
          uri
          slug
          date
          author {
            node {
              name
            }
          }
          categories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
    allWpCategory(filter: { parentId: { eq: null } }) {
      edges {
        node {
          name
          slug
          parentId
          id
          wpChildren {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    wp {
      tenjinSettings {
        acf {
          stacksMenuLabel
          destinationsMenuLabel
          sourcesMenuLabel
          trainingMenuLabel
          analysesMenuLabel
          trainingPage {
            ... on WpPage {
              id
              title
              content
              seo {
                canonical
                cornerstone
                focuskw
                fullHead
                metaDesc
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphAuthor
                opengraphDescription
                opengraphModifiedTime
                opengraphPublishedTime
                opengraphPublisher
                opengraphSiteName
                opengraphTitle
                opengraphType
                opengraphUrl
                readingTime
                title
                twitterDescription
                twitterTitle
              }
            }
          }
          destinationsPage {
            ... on WpPage {
              id
              title
            }
          }
          sourcesPage {
            ... on WpPage {
              id
              title
            }
          }
          analysesPage {
            ... on WpPage {
              id
              title
            }
          }
        }
      }
    }
  }
`
export default Trainings;
